import React from "react"
import { Redirect } from "react-router-dom"
import UserProfile from "../pages/Authentication/user-profile"
import UserGlobalSetting from "../pages/Authentication/user-constraint-setting"
// import CustomApi from "../pages/Authentication/custom-api";
// import CustomApiPublic from "../pages/Authentication/custom-api-public";
import UserChangePassword from "../pages/Authentication/changePassword"
import UserBilling from "../pages/Authentication/billing"
import PhoneNumbers from "../pages/PhoneNumbers/index"
import PhoneNumberPurchase from "../pages/PhoneNumbers/purchase"

//import UserUpgrade from "../pages/Authentication/UserUpgrade";
//import CoachUpgrade from "../pages/Authentication/CoachUpgrade";
import Login from "../pages/Authentication/Login";
//import UserToggle from "../pages/Authentication/UserToggle";
// import StaffLogin from "../pages/Authentication/StaffLogin";
//import ClientProfileComplete from "../pages/Authentication/ClientProfileComplete";


import ResetPassword from "../pages/Authentication/ResetPassword"
import Logout from "../pages/Authentication/Logout"
//import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Dashboard from "../pages/Dashboard/index"
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
//import SignupSelectPackage from "../pages/Authentication/SignupSelectPackage";
//import SipupPackage from "../pages/Authentication/SipupPackage"
//import SignupPayment from "../pages/Authentication/SignupPayment"
//import ProposalView from "../pages/Authentication/Proposal";


// import TableSetting from "../pages/SuperAdmin/TableSetting/TableSetting";
// import TableLeadSetting from "../pages/SuperAdmin/TableSetting/TableLeadSetting";
// import DomainSetting from "../pages/SuperAdmin/DomainSetting";
// import StandardProfileRequest from "../pages/SuperAdmin/StandardProfileRequest";
// import UserStandardProfileRequest from "../pages/SuperAdmin/UserStandardProfileRequest";



// import dragDrop from "../pages/Incall/index";
// import VideoMeeting from "../pages/Coaches/test-feature/meeting";


import PermissionList from "../pages/Permission/index";
import PermissionCreateUpdate from "../pages/Permission/PermissionCreateUpdate";
import Logs from "../pages/Logs/Logs";
import UserDetails from "../pages/Users/UserDetails"


import ActivityLog from "../pages/Logs/ActivityLog";

import TwilliousageLog from "../pages/Logs/TwilliousageLog";
import CreditConsumptionLog from "../pages/Logs/CreditConsumptionLog";
import CoachCreditConsumedHistory from "../pages/Logs/CreditConsumedHistory";


import Admin from "../pages/Users/Admin/Admin";
import AdminCreateUpdate from "../pages/Users/Admin/AdminCreateUpdate";


import TwillioSettings from "../pages/Settings/Integrations/TwillioSettings";

import EmailSettings from "../pages/Settings/EmailSettings/EmailSettings";
import Paymentsettings from "../pages/Settings/PaymentSettings/PaymentSettings";

import CoachPartners from "../pages/Settings/CoachPartners/CoachPartners";
import CoachCreateUpdate from "../pages/Settings/CoachPartners/CoachCreateUpdate";

import Teams from "../pages/Teams/index";
import TeamCreateUpdate from "../pages/Teams/TeamCreateUpdate";

import CMSContent from "../pages/Settings/CMS/Content.js";
import CMSContentCreateUpdate from "../pages/Settings/CMS/CreateUpdate.js";

import TransactionLog from "../pages/Settings/Reports/TransactionLog";
import LoginHistory from "../pages/Settings/Reports/LoginHistory";
import SubscriptionLog from "../pages/Settings/Reports/SubscriptionLog";
import NonSubscriptionLog from "../pages/Settings/Reports/NonSubscriptionLog";






//Coach Reports
// import CoachReports from "../pages/Coaches/Reports/index.js";


import SubscriptioHistory from "../pages/Settings/Reports/SubscriptioHistory";

import SubscriptionHistoryInvoice from "../pages/Settings/Reports/SubscriptionHistoryInvoice";

//import BuildWebsite from "../pages/Admin/BuildWebsite.js";

//import WLDomain from "../pages/Admin/WLDomain.js";
//import Theme from "../pages/Admin/Theme";
//import Branding from "../pages/Admin/Branding";
// import ManageContents from "../pages/Admin/ManageContents";
// import CreateContents from "../pages/Admin/CreateContents";
// import UpdateContents from "../pages/Admin/UpdateContents";


//import WLTheme from "../pages/Admin/WLTheme";


import ContactSupport from "../pages/ContactSupport/index";
import RecycleOptions from "../pages/Recycle/index";


import Managers from "../pages/Users/Coaches/Managers";
import AdminManagers from "../pages/Users/Coaches/AdminManagers";
import ManagerCreateUpdate from "../pages/Users/Coaches/ManagerCreateUpdate";
import AdminManagerCreateUpdate from "../pages/Users/Coaches/AdminManagerCreateUpdate";



// import TrainingCategory from "../pages/Coaching/Training/TrainingCategory";
// import TrainingCategoryCreateUpdate from "../pages/Coaching/Training/TrainingCategoryCreateUpdate";
// import TrainingCategoryReorder from "../pages/Coaching/Training/TrainingCategoryReorder";

//Training Section
// import TrainingCategorySections from "../pages/Coaching/Training/TrainingCategorySections";
// import TrainingSectionsQuiz from "../pages/Coaching/Training/TrainingSectionsQuiz";


//import TrainingCategorySectionCreateUpdate from "../pages/Coaching/Training/TrainingCategorySectionCreateUpdate";

//Training Content
// import TrainingContentAdd from "../pages/Coaching/Training/TrainingContentAdd";
// import TrainingContent from "../pages/Coaching/Training/TrainingContent";
// import TrainingContentView from "../pages/Coaching/Training/TrainingContentView";
// import ManageMediaContent from "../pages/Coaching/Training/ManageMediaContent";
// import TrainingContentEdit from "../pages/Coaching/Training/TrainingContentEdit";


//Tracker
// import TrackerAdd from "../pages/Coaching/Tracker/add";
// import Tracker from "../pages/Coaching/Tracker";
// import TrackerView from "../pages/Coaching/Tracker/view";
// import TrackerEdit from "../pages/Coaching/Tracker/edit";

// AssessmentQuestion
// import AssessmentQuestion from "../pages/Coaching/Assessment/Questions";
// import AssessmentQuestionView from "../pages/Coaching/Assessment/QuestionsView";


//Resources Content
// import ResourcesContent from "../pages/Coaching/Resources/ResourcesContent";
// import ResourceContentAdd from "../pages/Coaching/Resources/ResourceContentAdd";
// import ResourceContentView from "../pages/Coaching/Resources/ResourceContentView";
// import ResourceContentEdit from "../pages/Coaching/Resources/ResourceContentEdit";



//Resource Section
// import ResourcesCategorySections from "../pages/Coaching/Resources/ResourcesCategorySections";
// import ResourceCategorySectionCreateUpdate from "../pages/Coaching/Resources/ResourceCategorySectionCreateUpdate";


//Faqs
// import TrainingCategoryFaqs from "../pages/Coaching/Training/TrainingCategoryFaqs";
// import TrainingCategorySectionfaqCreateUpdate from "../pages/Coaching/Training/TrainingCategorySectionfaqCreateUpdate";



//Resouces 
// import ResourcesCategory from "../pages/Coaching/Resources/ResourcesCategory";
// import ResourceCategoryCreateUpdate from "../pages/Coaching/Resources/ResourceCategoryCreateUpdate";

// import Staff from "../pages/Users/Staff/Staff";
// import StaffCreateUpdate from "../pages/Users/Staff/StaffCreateUpdate";



/////////////////////////////////////////////////////
// import PackageDetails from "../pages/Package/PackageDetails"
import Packages from "../pages/Packages/Packages";
import PackagesCreate from "../pages/Packages/PackagesCreate";
import PackagesCreateUpdate from "../pages/Packages/PackagesCreateUpdate";
import PackagesAdd from "../pages/Packages/PackagesAdd";


import DefaultNotifications from "../pages/Settings/NotificationTemplatesSettings/Notification";
import NotificationCreateUpdate from "../pages/Settings/NotificationTemplatesSettings/NotificationCreateUpdate";

import Group from "../pages/Coaches/Group/Group";
import GroupCreateUpdate from "../pages/Coaches/Group/GroupCreateUpdate";

import CustomField from "../pages/Coaches/CustomField.js";


//import inbox from "../pages/Coaches/Inbox/index";
//import Leads from "../pages/Coaches/Lead/Leads";
import LeadsImport from "../pages/Coaches/Lead/LeadsImport";
import leads from "../pages/Coaches/Lead";
import leadCreateUpdate from "../pages/Coaches/Lead/ClientCreateUpdate";
// import LeadDetails from "../pages/Coaches/Lead/LeadDetails";
// import LeadsCreateUpdate from "../pages/Coaches/Lead/LeadsCreateUpdate";


// import ClientForms from "../pages/ClientForms";
// import ClientFormsCreate from "../pages/ClientForms/create.js";
// import ClientFormsUpdate from "../pages/ClientForms/update.js";
// import ClientPublicForm from "../pages/ClientForms/FormDetails.js"

// import companies from "../pages/Coaches/Companies";
// import CompanyCreateUpdate from "../pages/Coaches/Companies/CreateUpdate";
// import companyDetails from "../pages/Coaches/Companies/Details";


// import Filters from "../pages/Filter";
// import FilterAdd from "../pages/Filter/add.js";
// import FilterUpdate from "../pages/Filter/update.js";

import Campaign from "../pages/Campaign";
import CampaignCreate from "../pages/Campaign/create.js";
import CampaignUpdate from "../pages/Campaign/update.js";
import CampaignAttachment from "../pages/Campaign/attachment";

// import CampaignOutbound from "../pages/Campaign/outbound.js";
// import CampaignInbound from "../pages/Campaign/inbound.js";
// import CampaignIvrUpdate from "../pages/Campaign/ivr_update.js";
// import CampaignBatches from "../pages/Campaign/batches.js";
// import CampaignClientBatches from "../pages/Campaign/batches_clients_details.js";


// import CampaignReportSummary from "../pages/Campaign/reporting/summary.js";
// import CampaignReportInsight from "../pages/Campaign/reporting/insight"
// import CampaignReportCall from "../pages/Campaign/reporting/call.js";
// import CampaignReportVoice from "../pages/Campaign/reporting/voice.js";
// import CampaignReportRvm from "../pages/Campaign/reporting/rvm.js";
// import CampaignReportSms from "../pages/Campaign/reporting/sms.js";
// import CampaignReportEmail from "../pages/Campaign/reporting/email.js";
// import CampaignReportManual from "../pages/Campaign/reporting/manual.js";
// import CampaignLog from "../pages/Campaign/reporting/logs.js";


// import AioReportSummary from "../pages/Coaches/Reports/summary.js";
// import AioReportInsight from "../pages/Coaches/Reports/insight"
import AioReportCall from "../pages/Coaches/Reports/call.js";
// import AioReportVoice from "../pages/Coaches/Reports/voice.js";
// import AioReportRvm from "../pages/Coaches/Reports/rvm.js";
// import AioReportSms from "../pages/Coaches/Reports/sms.js";
// import AioReportEmail from "../pages/Coaches/Reports/email.js";
// import AioReportManual from "../pages/Coaches/Reports/manual.js";
// import AioLog from "../pages/Coaches/Reports/logs.js";


//Assessments 
// import AssesmentsList from "../pages/Assessment";
// import Assesments from "../pages/Assessment/content.js";
// import ImplementationContent from "../pages/Assessment/imp_content.js";


//Profit Center
// import ProfitCenter from "../pages/ProfitCenter/content.js";
// import ProgressTracker from "../pages/ProgressTracker/content.js";
//Training
//Products

// import AssesmentTrainingProducts from "../pages/Coaches/Assessments/Training/Products";
// import AssesmentTrainingProductContent from "../pages/Coaches/Assessments/Training/ProductContent";
// import AssesmentTrainingProductContentVideo from "../pages/Coaches/Assessments/Training/ProductContentVideo";
// import AssesmentTrainingTakeQuiz from "../pages/Coaches/Assessments/Training/AssesmentTrainingTakeQuiz";

// import CoachTraining from "../pages/Coaches/Assessments/Training/CoachTraining";
// import CoachTrainingView from "../pages/Coaches/Assessments/Training/CoachTrainingView";
// import TrainingSection from "../pages/Coaches/Assessments/Training/TrainingSection";

// import ClientTraining from "../pages/Coaches/Assessments/Training/ClientTraining";
// import ClientTrainingView from "../pages/Coaches/Assessments/Training/ClientTrainingView";
// import ClientTrainingSection from "../pages/Coaches/Assessments/Training/ClientTrainingSection";



//Resources Products
// import AssessmentsResourcesProducts from "../pages/Coaches/Assessments/Resources/Products";
// import AssesmentResourcesProductContentVideo from "../pages/Coaches/Assessments/Resources/ProductContentVideo";




//Campaign Automation
// import CampaignAutomation from "../pages/Campaign/automation/index";
// import AutomationFlow from "../pages/Campaign/automation/flow.js";

// import CampaignDrip from "../pages/Campaign/drips/index";
// import CampaignDripFlow from "../pages/Campaign/drips/flow.js";

// import CampaignBroadcasts from "../pages/Campaign/broadcasts/index";
// import CampaignBroadcastsFlow from "../pages/Campaign/broadcasts/flow.js";

import CampaignDialer from "../pages/Campaign/dialer";

import CallerStatus from "../pages/CallerStatus";








// import EmailTemplatescreate from "../pages/Coaches/Templates/Email/emailtemplatesAdd";
// import EmailTemplateslist from "../pages/Coaches/Templates/Email/EmailTemplateslist";


//
// import SMSTemplates from "../pages/Coaches/Templates/SMS/smstemplate";
// import SMSTemplatesAddEdit from "../pages/Coaches/Templates/SMS/smsTemplatesAddEdit";
// import SMSTemplatesEdit from "../pages/Coaches/Templates/SMS/SMSTemplatesEdit";

// import Tasks from "../pages/Tasks";
// import Pipeline from "../pages/Pipeline";
// import PipelineView from "../pages/Pipeline/details";
// import PipelineStatusFlow from "../pages/Pipeline/Automation/flow.js";

// import OnBoardTraining from "../pages/OnBoardTraining";



// Contract
// import Contract from "../pages/Contract";
// import ContractFormBuilder from "../pages/Contract/FormBuilder";
// import ContractFormMapping from "../pages/Contract/FormMapping";

// import CallTesting from "../pages/InitiateVideoCall";


// import VoiceTemplates from "../pages/Coaches/Templates/Voice/voicetemplate";
// import VoiceMailTemplatesAddEdit from "../pages/Coaches/Templates/Voice/VoiceMailTemplatesAddEdit";
// import VoiceMailTemplatesEdit from "../pages/Coaches/Templates/Voice/VoiceMailTemplatesEdit";


import ScriptTemplates from "../pages/Coaches/Templates/Script/scripttemplate";
import ScriptTemplatesAddEdit from "../pages/Coaches/Templates/Script/scriptTemplatesAddEdit";
import ScriptTemplatesEdit from "../pages/Coaches/Templates/Script/ScriptTemplatesEdit";
// import Chat from "../pages/Chat";

///Meeting
// import Meeting from "../pages/Meeting";
// import Calendar from "../pages/Meeting/index";
// import googleCalendar from "../pages/Meeting/googleCalendar";


// import MeetingCreate from "../pages/Meeting/create.js";
// import MeetingUpdate from "../pages/Meeting/update.js";
// import MettingCalander from "../pages/Meeting/calander.js"
// import MettingCalanderUpdate from "../pages/Meeting/update_meeting.js"


// import MeetingDetails from "../pages/Meeting/details.js"
// import appointmentList from "../pages/Meeting/appointmentList.js"




////////////////////////////////////////////////////////////////////

// import ClientFormDetails from "../pages/Clients/FormDetails.js"
// import ClientFormShow from "../pages/Clients/FormShow.js"
// import ClientContracts from "../pages/Clients"
// import ClientProfile from "../pages/Clients/profile.js"

// import TwillioSharing from "../pages/sharing/TwillioSharing.js"

import Verification from "../pages/Verification"
// import AccountNotification from "../pages/Verification/AccountNotification.js";
// import RenewSubscription from "../pages/Verification/RenewSubscription.js";




// import TrusthubOverview from "../pages/Trusthub/overview.js"
// import Trusthub from "../pages/Trusthub/trusthub.js"
// import TrusthubSubmitRequest from "../pages/Trusthub/submit_request.js"
// import TrusthubUpgrade from "../pages/Trusthub/TrusthubUpgrade.js"
// import SystemWarning from "../pages/Notification/SystemWarning"

// import VideoRoomHost from "../pages/RoomVideo/VideoChat"

// import VideoRoomRemote from "../pages/RoomVideo/RemoteVideoChat"

// import Waybook from "../pages/Waybook"

const userRoutes = [
    { path: "/users/admin/coach/add", component: AdminManagerCreateUpdate, requiredRoles: ['coaches'] },
    { path: "/users/admin/coach/edit/:id", component: AdminManagerCreateUpdate, requiredRoles: ['coaches'] },
    { path: "/users/admin/coach", component: AdminManagers, requiredRoles: ['coaches'] },

    //Staff Management
    // { path: "/users/staff/add", component: StaffCreateUpdate, requiredRoles: ['staff'] },
    // { path: "/users/staff/edit/:id", component: StaffCreateUpdate, requiredRoles: ['staff'] },
    // { path: "/users/staff", component: Staff, requiredRoles: ['staff'] },

    // permissionLibrary
    // { path: "/users/permissionLibrary/list", component: PermissionList, requiredRoles: ['permission_library'] },
    // { path: "/users/permissionLibrary/add", component: PermissionCreateUpdate, requiredRoles: ['permission_library'] },
    // { path: "/users/permissionLibrary/edit/:id", component: PermissionCreateUpdate, requiredRoles: ['permission_library'] },
    // // Users

    //Packages
    { path: "/packages/list/add/:type", component: PackagesAdd, requiredRoles: ['coach_packages','credit_packages'] },
    { path: "/packages/list/addNew/:type", component: PackagesCreate, requiredRoles: ['coach_packages','credit_packages'] },
    { path: "/packages/list/edit/:id", component: PackagesCreateUpdate, requiredRoles: ['coach_packages','credit_packages'] },
    { path: "/packages/list/:type", component: Packages, requiredRoles: ['coach_packages','credit_packages'] },

    // { path: "/packages/proposal/list", component: Proposals, requiredRoles: ['proposal_list'] },
    // { path: "/packages/proposal/add", component: ProposalsAdd, requiredRoles: ['proposal_list'] },
    // { path: "/packages/proposal/edit/:id", component: ProposalsAdd, requiredRoles: ['proposal_list'] },

    //Revenue Goals Settings
    //{ path: "/packages/revenueGoals", component: RevenueGoalsSettings, requiredRoles: ['revenue_goals'] },

    //Revenue Products
    // { path: "/packages/revenueProducts", component: RevenueProducts, requiredRoles: ['revenue_products'] },
    // { path: "/settings/revenueProduct/add", component: RevenueProductAdd, requiredRoles: ['revenue_products'] },
    // { path: "/settings/revenueProduct/edit/:id", component: RevenueProductAdd, requiredRoles: ['revenue_products'] },
    //Packages

    //Credits
    // { path: "/credits/consume/criteria", component: CreditConsumptionCriteria, requiredRoles: ['consume_criteria'] },
    // { path: "/credits", component: FreeCredit, requiredRoles: ['free_credit'] },
    //Credits

    //Training
    //Category

    
    // { path: "/training/reorder", component: TrainingCategoryReorder, requiredRoles: ['training'] },
    // { path: "/training/category/add", component: TrainingCategoryCreateUpdate, requiredRoles: ['training'] },
    // { path: "/training/category/edit/:id", component: TrainingCategoryCreateUpdate, requiredRoles: ['training'] },
    // { path: "/training", component: TrainingCategory, requiredRoles: ['training'] },

    // //Training Content
    // { path: "/training/content", component: TrainingContent, requiredRoles: ['training'] },
    // { path: "/training/content/add", component: TrainingContentAdd, requiredRoles: ['training'] },
    // { path: "/training/content/view/:id", component: TrainingContentView, requiredRoles: ['training'] },
    // { path: "/training/content/edit/:id", component: TrainingContentEdit, requiredRoles: ['training'] },

    //Sections
    // { path: "/training/category/view/:id", component: TrainingCategorySections, requiredRoles: [] },
    // { path: "/training/quiz/question/:id", component: TrainingSectionsQuiz, requiredRoles: [] },
    // { path: "/training/categorysection/add/:cat_id", component: TrainingCategorySectionCreateUpdate, requiredRoles: [] },
    // { path: "/training/categorysection/edit/:id", component: TrainingCategorySectionCreateUpdate, requiredRoles: [] },


    //Resources
    // { path: "/resources", component: ResourcesCategory, requiredRoles: ['resources'] },
    // { path: "/resource/category/add", component: ResourceCategoryCreateUpdate, requiredRoles: ['resources'] },
    // { path: "/resource/category/edit/:id", component: ResourceCategoryCreateUpdate, requiredRoles: ['resources'] },


    //Resource Sections
    // { path: "/resource/category/view/:id", component: ResourcesCategorySections, requiredRoles: ['resources'] },
    // { path: "/resource/categorysection/add/:cat_id", component: ResourceCategorySectionCreateUpdate, requiredRoles: ['resources'] },
    // { path: "/resource/categorysection/edit/:id", component: ResourceCategorySectionCreateUpdate, requiredRoles: ['resources'] },

    //Resources Content
    // { path: "/resources/content", component: ResourcesContent, requiredRoles: ['resources'] },
    // { path: "/resource/content/add", component: ResourceContentAdd, requiredRoles: ['resources'] },
    // { path: "/resource/content/edit/:id", component:  ResourceContentEdit, requiredRoles: ['resources'] },
    // { path: "/resource/content/view/:id", component: ResourceContentView, requiredRoles: ['resources'] },

    // Manage Media
    //{ path: "/manage/media", component: ManageMediaContent, requiredRoles: [] },

    //Tracker
    // { path: "/tracker", component: Tracker, requiredRoles: ['tracker_steps'] },
    // { path: "/tracker/add", component: TrackerAdd, requiredRoles: ['tracker_steps'] },
    // { path: "/tracker/view/:id", component: TrackerView, requiredRoles: ['tracker_steps'] },
    // { path: "/tracker/edit/:id", component: TrackerEdit, requiredRoles: ['tracker_steps'] },

    //Assessment Question
    // { path: "/assessment/questions", component: AssessmentQuestion, requiredRoles: ['assessment_questions'] },
    // { path: "/assessment/questions/view/:id", component: AssessmentQuestionView, requiredRoles: ['assessment_questions'] },

    

    //Training

    //Activity 

    //  Log 
    { path: "/:type/activity/log", component: ActivityLog, requiredRoles: ['activity_log'] },
    //Twillio Usage Log
    { path: "/twillio/usagelog", component: TwilliousageLog, requiredRoles: ['twillio_usagelog'] },
    //Credit Consumption Log
    { path: "/credit/consumptionlog", component: CreditConsumptionLog, requiredRoles: ['credit_consumption_log'] },
    //  login/history
    { path: "/:path/login/history", component: LoginHistory, requiredRoles: ['coach_login_history'] },
    { path: "/:path/login/history/:date", component: LoginHistory, requiredRoles: [] },
    // Subscription Log
    { path: "/:path/subscription/log", component: SubscriptionLog, requiredRoles: ['coach_subscription_log'] },
    { path: "/:path/nonsubscription/log", component: NonSubscriptionLog, requiredRoles: ['coach_nonsubscription_log'] },
    //Activity 

    //CMS
    { path: "/cms/:path/add", component: CMSContentCreateUpdate, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    { path: "/cms/:path/view/:id", component: CMSContentCreateUpdate, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    { path: "/cms/:path", component: CMSContent, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    //Notification Templates
    { path: "/default/template/add", component: NotificationCreateUpdate, requiredRoles: ['default_template'] },
    { path: "/default/template/edit/:id", component: NotificationCreateUpdate, requiredRoles: ['default_template'] },
    { path: "/default/template", component: DefaultNotifications, requiredRoles: ['default_template'] },
    //CMS

    // Settings
    //{ path: "/settings/integration", component: TwillioSettings, requiredRoles: ['settings_integration'] },
    { path: "/settings/email", component: EmailSettings, requiredRoles: ['settings_email'] },
    //{ path: "/settings/domain", component: DomainSetting, requiredRoles: ['settings_domain'] },
    //{ path: "/standard-profile/request", component: StandardProfileRequest, requiredRoles: ['standard_profile_request'] },
    //{ path: "/standard-profile/request/:id", component: UserStandardProfileRequest, requiredRoles: ['standard_profile_request'] },
    // Settings
    //Admin routes 

    //Coach routes

    //Inbox
    //{ path: "/chat/inbox/:id/:coach_id", component: inbox, requiredRoles: ['inbox'] },
    
    // Leads
    { path: "/import/phone/list", component: LeadsImport, requiredRoles: ['leads'] },
    { path: "/contact/list", component: leads, requiredRoles: ['leads'] },
    { path: "/contact/edit/:id/:coach_id", component: leadCreateUpdate, requiredRoles: ['leads'] },
    { path: "/contact/add/", component: leadCreateUpdate, requiredRoles: ['leads'] },
    // { path: "/lead/dialer/:id/:coach_id", component: leadDetails, requiredRoles: ['leads'] },


    //lead Forms
    // { path: "/lead/forms", component: ClientForms, requiredRoles: ['leads'] },
    // { path: "/lead/forms/add", component: ClientFormsCreate, requiredRoles: ['leads'] },
    // { path: "/lead/forms/edit/:id", component: ClientFormsUpdate, requiredRoles: ['leads'] },


    // Client 
    // { path: "/client/list", component: Clients, requiredRoles: ['clients'] },
    //{ path: "/client/edit/:id/:coach_id", component: ClientCreateUpdate, requiredRoles: ['clients'] },
    //{ path: "/client/add/", component: ClientCreateUpdate, requiredRoles: ['clients'] },
    //{ path: "/client/dialer/:id/:coach_id", component: ClientDetails, requiredRoles: ['clients'] },

    //My coaches
    // { path: "/users/coach/add", component: ManagerCreateUpdate, requiredRoles: ['my_coaches'] },
    // { path: "/users/coach/edit/:id", component: ManagerCreateUpdate, requiredRoles: ['my_coaches'] },
    // { path: "/users/coach", component: Managers, requiredRoles: ['my_coaches'] },
    // { path: "/coach/dialer/:id/:coach_id/:wl_admin_id", component: CoachDetails, requiredRoles: ['my_coaches'] },

    // Groups
    { path: "/phone/list/add", component: GroupCreateUpdate, requiredRoles: ['groups_and_tags'] },
    { path: "/phone/list/edit/:id", component: GroupCreateUpdate, requiredRoles: ['groups_and_tags'] },
    { path: "/phone/list", component: Group, requiredRoles: ['groups_and_tags'] },

    // CustomField
    { path: "/custom/field/list", component: CustomField, requiredRoles: ['custom_fields'] },

    //Sales
    /// Pipeline
    // { path: "/pipeline/list", component: Pipeline, requiredRoles: ['pipeline'] },
    // { path: "/view/pipeline/:pipeline_id", component: PipelineView, requiredRoles: ['pipeline'] },
    // { path: "/pipeline/status/flow/:status_id", component: PipelineStatusFlow, requiredRoles: ['pipeline'] },

    /// OnBoardTraining 
    //{ path: "/training/status/list", component: OnBoardTraining, requiredRoles: ['onboard_training'] },

    /// Contract
    // { path: "/contract/list", component: Contract, requiredRoles: ['contracts'] },
    // { path: "/contract/formbuilder/:id", component: ContractFormBuilder, requiredRoles: ['contracts'] },
    // { path: "/contract/form/mapping/:id", component: ContractFormMapping, requiredRoles: ['contracts'] },


    //Invoice
    // { path: "/invoice/list", component: Invoice, requiredRoles: ['invoices'] },
    // { path: "/invoice/add/:id?/:type?", component: InvoiceCreate, requiredRoles: ['invoices'] },
    // { path: "/invoice/edit/:id", component: InvoiceUpdate, requiredRoles: ['invoices'] },


    //Campaigns
    { path: "/campaign/list", component: Campaign, requiredRoles: ['campaign_list'] },
    { path: "/campaign/add/", component: CampaignCreate, requiredRoles: ['campaign_list'] },
    { path: "/campaign/attachment/:id?", component: CampaignAttachment, requiredRoles: [] },
    { path: "/campaign/edit/:id", component: CampaignUpdate, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/outbound/:id", component: CampaignOutbound, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/inbound/:id", component: CampaignInbound, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/ivr/update/:id/:ivr_id", component: CampaignIvrUpdate, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/batches/:id/:batch_id/:batch_type", component: CampaignBatches, requiredRoles: ['campaign_list'] },
    // { path: "/clients/batches/detail/:id/:batch_id", component: CampaignClientBatches, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/summary/:id", component: CampaignReportSummary, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/insights/:id", component: CampaignReportInsight, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/call/:id", component: CampaignReportCall, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/voice/:id", component: CampaignReportVoice, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/rvm/:id", component: CampaignReportRvm, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/sms/:id", component: CampaignReportSms, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/email/:id", component: CampaignReportEmail, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/manual/:id", component: CampaignReportManual, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/activity/:id", component: CampaignLog, requiredRoles: ['campaign_list'] },
    // //Campaign Automation
    // { path: "/campaign/automation/list/:id", component: CampaignAutomation, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/automation/flow/:id/:group_id/:flow_id", component: AutomationFlow, requiredRoles: ['campaign_list'] },
    //Campaign Event Reminder
    //{ path: "/campaign/eventreminder/:id/:group_id/:flow_id", component: EventReminderFlow, requiredRoles: ['campaign_list'] },
    //Campaign drips
    // { path: "/campaign/drips/:id", component: CampaignDrip, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/drips/flow/:id/:group_id/:flow_id", component: CampaignDripFlow, requiredRoles: ['campaign_list'] },
    //Campaign Broadcasts
    // { path: "/campaign/broadcasts/:id", component: CampaignBroadcasts, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/broadcasts/flow/:id/:groupId", component: CampaignBroadcastsFlow, requiredRoles: ['campaign_list'] },




    // CallerStatus
    { path: "/callstatus/list", component: CallerStatus, requiredRoles: ['call_status'] },

  
    //Templates
    //Email
    // { path: "/emailtemplates/list", component: EmailTemplates, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/add/", component: EmailTemplateslist, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/edit/:id", component: EmailTemplatesedit, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/create/:id", component: EmailTemplatescreate, requiredRoles: ['template_library'] },


    //SMS Template
    // { path: "/smstemplates/list", component: SMSTemplates, requiredRoles: ['template_library'] },
    // { path: "/smstemplates/add/", component: SMSTemplatesAddEdit, requiredRoles: ['template_library'] },
    // { path: "/smstemplate/edit/:id", component: SMSTemplatesEdit, requiredRoles: ['template_library'] },

    //Script Template
    { path: "/agent/script/list", component: ScriptTemplates, requiredRoles: ['template_library'] },
    { path: "/agent/script/add/", component: ScriptTemplatesAddEdit, requiredRoles: ['template_library'] },
    { path: "/agent/script/edit/:id", component: ScriptTemplatesEdit, requiredRoles: ['template_library'] },

    //VoiceMail Template
    // { path: "/voicetemplates/list", component: VoiceTemplates, requiredRoles: ['template_library'] },
    // { path: "/voicemailtemplate/add/", component: VoiceMailTemplatesAddEdit, requiredRoles: ['template_library'] },
    // { path: "/voicemailtemplate/edit/:id", component: VoiceMailTemplatesEdit, requiredRoles: ['template_library'] },

  
    //Coaching
    //Assessment

    // { path: "/assessment/list/:type?", component: AssesmentsList, requiredRoles: ['assessment_list'] },
    // { path: "/assessment/:id/:slug", component: Assesments, requiredRoles: [] },
    // { path: "/assessment/:id/:slug/:result", component: Assesments, requiredRoles: [] },
    // { path: "/assessment/:id/:slug/:cal/:key", component: ImplementationContent, requiredRoles: [] },
    // { path: "/profit/center/:id/:slug", component: ProfitCenter, requiredRoles: [] },
    // { path: "/progress/tracker/:id/:slug", component: ProgressTracker, requiredRoles: [] },

    //Training
    // { path: `/coach/trainings`, component: CoachTraining, requiredRoles: ['trainings'] },
    // { path: `/coach/trainings/view/:id*`, component: CoachTrainingView, requiredRoles: ['trainings'] },
    // { path: `/training/section/:id`, component: TrainingSection, requiredRoles: ['trainings'] },

    //Resources Section 
    // { path: "/assesments/resources", component: AssessmentsResourcesProducts, requiredRoles: ['resources'] },
    // { path: "/resource/product/contentview/:id/:cont_id", component: AssesmentResourcesProductContentVideo, requiredRoles: ['resources'] },


    //Report
    //Reporting
    // { path: "/report/summary", component: AioReportSummary, requiredRoles: ['reporting'] },
    // { path: "/report/insights", component: AioReportInsight, requiredRoles: ['reporting'] },
    { path: "/call/log", component: AioReportCall, requiredRoles: [] },

 	{ path: "/recycle/option/:id?", component: RecycleOptions, requiredRoles: [] },
 	

    // { path: "/report/voice", component: AioReportVoice, requiredRoles: ['reporting'] },
    // { path: "/report/rvm", component: AioReportRvm, requiredRoles: ['reporting'] },
    // { path: "/report/sms", component: AioReportSms, requiredRoles: ['reporting'] },
    // { path: "/report/email", component: AioReportEmail, requiredRoles: ['reporting'] },
    // { path: "/report/manual", component: AioReportManual, requiredRoles: ['reporting'] },
    // { path: "/report/activity", component: AioLog, requiredRoles: ['reporting'] },

    //Credit Log 
    //{ path: "/report/credithistory", component: CoachCreditConsumedHistory, requiredRoles: ['credit_log'] },

    //Settings

    //Coach Partner Settings
    { path: "/agents/list", component: CoachPartners, requiredRoles: [] },
    { path: "/agents/edit/:id", component: CoachCreateUpdate, requiredRoles: [] },
    { path: "/agents/add/", component: CoachCreateUpdate, requiredRoles: [] },

     //Coach Partner Settings
    { path: "/teams/list", component: Teams, requiredRoles: [] },
    { path: "/teams/edit/:id", component: TeamCreateUpdate, requiredRoles: [] },
    { path: "/teams/add/", component: TeamCreateUpdate, requiredRoles: [] },

    //Credits
    //{ path: "/settings/credits", component: Creditsettings, requiredRoles: ['credits'] },

    //Calendar
    // { path: "/settings/calendar", component: Calendar, requiredRoles: ['calendar'] },
    // { path: "/settings/google/calendar/:id", component: googleCalendar, requiredRoles: [] },

    //PhoneNumber
   { path: "/caller/numbers/list", component: PhoneNumbers, requiredRoles: ['phone_numbers'] },
    { path: "/caller/numbers/purchase", component: PhoneNumberPurchase, requiredRoles: ['phone_numbers'] },
    
    //Billing
    { path: "/settings/billing", component: UserBilling, requiredRoles: ['billing'] },

    //TransactionLog
    { path: "/settings/transactionlog", component: TransactionLog, requiredRoles: ['transaction_log'] },

    //Api
    //{ path: "/settings/custom/api", component: CustomApi, requiredRoles: ['api'] },

    //Builder
    // { path: "/build/website", component: BuildWebsite, requiredRoles: ['website_builder'] },
    // { path: "/branding", component: Branding, requiredRoles: ['website_builder'] },
    // { path: "/manage-contents", component: ManageContents, requiredRoles: ['website_builder'] },
    // { path: "/manage-products", component: ManageProducts, requiredRoles: ['website_builder'] },
    // { path: "/create/content", component: CreateContents, requiredRoles: ['website_builder'] },

    //Coach routes

    //{ path: "/trainings/:id*", component: Waybook, requiredRoles: [] },
    //Commom Routes
    { path: "/settings/payment", component: Paymentsettings, requiredRoles: ['settings_payment','payment'] },
    { path: "/dashboard", component: Dashboard, requiredRoles: [] },
    //{ path: "/chat/:id?/:type?", component: Chat, requiredRoles: [] },
    { path: "/settings/profile", component: UserProfile, requiredRoles: [] },
    { path: "/profile/password", component: UserChangePassword, requiredRoles: [] }, 
    { path: "/settings/global", component: UserGlobalSetting, requiredRoles: ['global_constraint_setting'] },


   
    //{ path: "/settings/upgrade", component: UserUpgrade, requiredRoles: [] },
    // { path: "/test/feature", component: dragDrop, requiredRoles: [] },
    // { path: "/test/meeting", component: VideoMeeting, requiredRoles: [] },
    // Coach Upgarde
    //{ path: "/settings/coachpartner/packages", component: CoachUpgrade, requiredRoles: [] },

    //Resellers management
    { path: "/users/admin/add", component: AdminCreateUpdate, requiredRoles: [] },
    { path: "/users/admin/edit/:id", component: AdminCreateUpdate, requiredRoles: [] },
    { path: "/users/admin/", component: Admin, requiredRoles: [] },
    { path: "/logs", component: Logs, requiredRoles: ['Logs'] },
    { path: "/user-profile/:id", component: UserDetails, requiredRoles: [] },
    { path: "/pages-blank", component: PagesBlank, requiredRoles: [] },
    // coaches Reports
    //{ path: "/aio/reporting", component: CoachReports, requiredRoles: [] },
    //{ path: "/domain/setting", component: WLDomain, requiredRoles: [] },
    //{ path: "/theme/", component: Theme, requiredRoles: [] },
    //{ path: "/settings/themes", component: WLTheme, requiredRoles: [] },
    // Api Credentials
    //{ path: "/apicredentials", component: ApiCredentials, requiredRoles: [] },
    //{ path: "/apicredentials/edit/:id", component: ApiCredentialsCreateUpdate, requiredRoles: ['Api Credentials'] },
    // RVM(Slybroadcast) Settings
    //{ path: "/settings/integration/rvm", component: RvmSettings, requiredRoles: [] },
    // Email Packages
    // { path: "/email/add", component: EmailCreateUpdate, requiredRoles: ['Email'] },
    // { path: "/email/edit/:id", component: EmailCreateUpdate, requiredRoles: ['Email'] },
    // { path: "/emails", component: Email, requiredRoles: ['Email'] },

    // { path: "/credit/logs/:id", component: CreditsLog, requiredRoles: [] },


    { path: "/contact/support", component: ContactSupport, requiredRoles: [] },

    // ClientTraining
    // { path: `/client/trainings`, component: ClientTraining, requiredRoles: [] },
    // { path: `/client/trainings/view/:id*`, component: ClientTrainingView, requiredRoles: [] },
    // { path: `/client/training/section/:id`, component: ClientTrainingSection, requiredRoles: [] },

    // //Coach Training Section
    // { path: "/assessments/trainings", component: AssesmentTrainingProducts, requiredRoles: [] },
    // { path: "/training/product/content/:id/:index?", component: AssesmentTrainingProductContent, requiredRoles: [] },
    // // { path: "/training/product/content/:id/:cont_id", component: AssesmentTrainingProductContent, requiredRoles: [] },
    // { path: "/training/product/contentvideo/:id/:cont_id/:index?", component: AssesmentTrainingProductContentVideo, requiredRoles: [] },

    // { path: "/take/quiz/:id", component: AssesmentTrainingTakeQuiz, requiredRoles: [] },
    // //Faq's
    // { path: "/training/category/faqs/:id", component: TrainingCategoryFaqs, requiredRoles: [] },
    // { path: "/training/categorysection/faq/edit/:id", component: TrainingCategorySectionfaqCreateUpdate, requiredRoles: [] },
    // { path: "/training/categorysection/faq/add/:cat_id", component: TrainingCategorySectionfaqCreateUpdate, requiredRoles: [] },

    // //Tracking Code
    // { path: "/settings/trackingcode", component: Trackingcode, requiredRoles: [] },
    // //Email Packages

    { path: "/:path/subscription/history/:sub_id/:user_id", component: SubscriptioHistory, requiredRoles: [] },
    { path: "/:path/subscription/history/invoice/:sub_id/:user_id", component: SubscriptionHistoryInvoice, requiredRoles: [] },

    //Credit Log
    //TableSetting
    //{ path: "/tablessetting", component: TableSetting, requiredRoles: [] },
    //{ path: "/settings/clientstable", component: TableLeadSetting, requiredRoles: [] },

    //TableSetting

    // Leads 
    // { path: "/leads", component: Leads, requiredRoles: [] },
    // { path: "/leads/edit/:id/:manager_id", component: LeadsCreateUpdate, requiredRoles: [] },
    // { path: "/leads/add/", component: LeadsCreateUpdate, requiredRoles: [] },

    // { path: "/lead-details/:id/:manager_id", component: LeadDetails, requiredRoles: [] },
    //LEads


    // Leads 
    // { path: "/company/list", component: companies, requiredRoles: [] },
    // { path: "/company/edit/:id/:coach_id", component: CompanyCreateUpdate, requiredRoles: [] },
    // { path: "/company/add/", component: CompanyCreateUpdate, requiredRoles: [] },
    // { path: "/company/dialer/:id/:coach_id", component: companyDetails, requiredRoles: [] },


    // Tasks 
    //{ path: "/tasks/list", component: Tasks, requiredRoles: [] },
    // Call Testing 
    // { path: "/test/sample", component: CallTesting, requiredRoles: [] },
    // { path: "/filter/list", component: Filters, requiredRoles: [] },
    // { path: "/filter/add", component: FilterAdd, requiredRoles: [] },
    // { path: "/filter/edit/:id", component: FilterUpdate, requiredRoles: [] },
    // { path: "/meeting/list", component: Meeting, requiredRoles: [] },
    // { path: "/meeting/add/", component: MeetingCreate, requiredRoles: [] },
    // { path: "/meeting/edit/:id", component: MeetingUpdate, requiredRoles: [] },
    // { path: "/appointment/list/:id", component: appointmentList, requiredRoles: [] },
    // { path: "/invoice/preview/:id", component: InvoiceDetail, requiredRoles: [] },
    // { path: "/invoice/pay/:id", component: InvoicePay, requiredRoles: [] },
    // //Test
    // { path: "/emailtemplatetesting", component: EmailTemplateslist, requiredRoles: [] },
    // { path: "/system/warnings", component: SystemWarning, requiredRoles: [] },
    // { path: "/form/details", component: ClientFormDetails, requiredRoles: [] },
    // { path: "/show/form", component: ClientFormShow, requiredRoles: [] },
    // { path: "/contracts", component: ClientContracts, requiredRoles: [] },
    // { path: "/client/profile", component: ClientProfile, requiredRoles: [] },
    // { path: "/view/meeting", component: MeetingDetails, requiredRoles: [] },
    // { path: "/video/room/host", component: VideoRoomHost, requiredRoles: [] },
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => < Redirect to="/dashboard" />, requiredRoles: [] },
    { component: Pages404, requiredRoles: [] },
]
const authRoutes = [
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
    { path: "/reset/password/:token/:email", component: ResetPassword },
    // { path: "/register", component: Register },
    // { path: "/signup/account/info/:package_id", component: SipupPackage },
    // { path: "/signup/select/package", component: SignupSelectPackage },
    // { path: "/signup/payment/:package_id/:user_id", component: SignupPayment },
    { path: "/pages-404", component: Pages404 },
    { path: "/pages-500", component: Pages500 },
    //{ path: "/user/toggle", component: UserToggle },
    //{ path: "/staff/login", component: StaffLogin },

    // { path: "/complete/profile", component: ClientProfileComplete },
    // { path: "/client/form/details", component: ClientFormDetails, requiredRoles: [] },
    // { path: "/meet/:time/:id", component: MettingCalander, requiredRoles: [] },

    // { path: "/reschedule/meeting/:calendar_id/:meeting_id", component: MettingCalanderUpdate, requiredRoles: [] },
    // { path: "/proposal/:id", component: ProposalView },
    // { path: "/client-form/:id/:slug", component: ClientPublicForm },
    // { path: "/video/room/remote/:roomid/:hostid", component: VideoRoomRemote },

    // { path: "/public/invoice/pay/:id", component: PublicInvoicePay },

    // { path: "/send/account/notification/:uid/:id/:reason", component: AccountNotification },
    // { path: "/renew/subscription/:uid/:id/:reason", component: RenewSubscription },
    // { path: "/custom/api", component: CustomApiPublic},

    // { path: "/twilio", component: TwillioSharing, requiredRoles: [] },
    // {  component: Pages404 },
]

const noHeaderRoutes = [
    /// campaign/outbounddialer
    { path: "/campaign/start", component: CampaignDialer, requiredRoles: [] },
    { path: "/verification/wizard", component: Verification, requiredRoles: [] },
    // { path: "/content/:id/:page", component: UpdateContents, requiredRoles: [] },
    // { path: "/trusthub/overview", component: TrusthubOverview, requiredRoles: [] },
    // { path: "/trusthub", component: Trusthub, requiredRoles: [] },
    // { path: "/trusthub/submit_request", component: TrusthubSubmitRequest, requiredRoles: [] },
    // { path: "/trusthub/upgrade", component: TrusthubUpgrade, requiredRoles: [] },
  
    // {  component: Pages404 },
]


export { userRoutes, authRoutes, noHeaderRoutes }