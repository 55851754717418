import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    Table,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardHeader,
    Container
} from "reactstrap"
import {
    AvForm,
    AvField,
    AvGroup,
    AvFeedback,
    AvInput,
} from "availity-reactstrap-validation";
import Select from "react-select";

import ClientCreateUpdate from "./ClientCreateUpdate";

import { toast, ToastContainer, Flip } from "react-toastify";
import { ApiUrl, PusherKey } from "../../../config"
import { bcrypt, convertPhoneFormat, dcrypt, isObjectEmpty, uInfo, uToken } from "../../../useToken"

import { Dropdown } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import Phone from "../../InitiateCall/Phone";
import Dialler from "../../InitiateCall/Dialler2";
import swal from 'sweetalert';
import Pusher from 'pusher-js';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CampaignDialer = props => {
    let history = useHistory();
    let query = useQuery();
    const getAlert = () => {
    }
    const [info, setInfo] = useState({});
    const [mood, setMood] = useState("Excellent");
    const [dialer_type, setDialerType] = useState(query.get("type") ? query.get("type"):"auto_dialer");
    const [campaignInfo, setCampaignInfo] = useState({});
    const [DynamicModal, setDynamicModal] = useState(false);
    const [DynamicModalName, setDynamicModalName] = useState("");
    const [templates, setSmsTemplates] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [emailSetting, setEmailSetting] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [smsBody, setSmsBody] = useState("");
    const [token, setToken] = useState("");
    const [subject, setSubject] = useState("");
    const [html, setHtml] = useState("");
    const [EmailModal, setEmailModal] = useState(false);
    const [pipeline, setPipeline] = useState({});
    const [pipelineStatus, setPipelineStatus] = useState([]);
    const [masterPipelines, setMasterPipelines] = useState([]);
    const [masterPipelineId, setMasterPipelineId] = useState(0);
    const [selectedPipeline, setSelectedPipeline] = useState({});
    const [pipelineModal, setPipelineModal] = useState(false);
    const [coachPartners, setCoachPartners] = useState([]);
    const [type, setType] = useState("");
    const [page3Class, setpage3Class] = useState("");
    const [page4Class, setpage4Class] = useState("");
    const [page5Class, setpage5Class] = useState("");
    const [activity, setActivity] = useState([]);
    const [tag, setTag] = useState("");
    const [callToken, setCallToken] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [dispositionId, setDispositionId] = useState(0);
    const [dispositionStatus, setDispositionStatus] = useState(true);
    const [callStatus, setCallStatus] = useState([]);
    const [scriptBody, setScriptBody] = useState("");
    const [CallSid, setCallSid] = useState("");
    const [template_id, setTemplateId] = useState(0);
    const [activeTab, setActiveTab] = useState('contact-info');
    const [total_calls, setTotalCalls] = useState('0');
    const [calls_duration, setCallsDuration] = useState('00:00:00');
    const [paused_duration, setPausedDuration] = useState('00:00:00');
    const [total_sales, setTotalSales] = useState('0');
    const [total_call_back, setTotalCallBack] = useState('0');
    // const clientId = (dialer_type === 'auto_dialer') ? info.client_id : 0;
    // const coachId = (dialer_type === 'auto_dialer') ? info.coach_id : 0;
    const [clientId, setClientId] = useState((dialer_type === 'auto_dialer') ? info.client_id : 0);
    const [coachId, setCoachId] = useState((dialer_type === 'auto_dialer') ? info.coach_id : 0);
    const [agentNote, setAgentNote] = useState("");
    const [contactMood, setContactMood] = useState("");
    // Create the paramsters object
    const paramsters = {
        token: callToken,
        from_number: campaignInfo.from_number?campaignInfo.from_number:uInfo().outbound_phone,
        phoneno: info.phone
                ? info.phone:"",
        record: "false",
        client_id: clientId,
        coach_id: coachId,
        coach_partner_id: uInfo().user_id,
        campaign_id: campaignInfo.campaign_id
    };

    const getCallSid = async (call_sid) => {
        setCallSid(call_sid);
    };

  
    const handleNoteChange = async (note) => {

        fetch(`${ApiUrl}` + "submit/agent/note", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({client_id: info.client_id, campaign_id: campaignInfo.campaign_id,activity:note }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    // swal({
                    //     text: "Updated successfully.",
                    //     icon: "success",
                    // });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleMoodChange = async (contact_mood) => {

        fetch(`${ApiUrl}` + "submit/contact/mode", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({client_id: info.client_id, campaign_id: campaignInfo.campaign_id,activity:contact_mood }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    setInfo((prevInfo) => ({
                        ...prevInfo, // Preserve the other properties in info
                        contact_mood: contact_mood, // Update the contact_mood
                    }));
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


   

    const handleOutCall = () => {
        fetch(`${ApiUrl}` + "voice/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setCallToken(response.data.token);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

   
    useEffect(() => { 
        if (query.get("id")) {
            getCompaignOutboundSetting();
        }
    }, []);

useEffect(() => {
  
        const pusher = new Pusher(PusherKey, { cluster: 'mt1' });
        const channel = pusher.subscribe('call-channel');
        channel.bind('call-connected-event', function (data) {
            console.log(data.client_id, 'Pusher called');
             console.log(data.client_id, 'pusher called')

            fetch(`${ApiUrl}` + "get/connected/lead/" + data.client_id, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    console.log(response.data.client_rec,'coach header')
                        setInfo(response.data.client_rec);
                        setAgentNote(response.data.agent_note);
                        setContactMood(response.data.contact_mood);
                        setClientId(response.data.client_rec.client_id);
                        setCoachId(response.data.client_rec.coach_id);
                        getCallHistory(response.data.client_rec.client_id, data.campaign_id);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        });

        return () => {
            pusher.unsubscribe('call-channel');
        };
    
}, []);  // Depend on campaignInfo so it runs only when campaignInfo changes
const getCompaignOutboundSetting = () => {
        let url = "get/camapign/setting/" + dcrypt(query.get("id")) + '/' + dialer_type;
        if (query.get("id") && dialer_type ==='auto_dialer') {
            fetch(`${ApiUrl}` + url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        let records = response.data;
                        if (isObjectEmpty(records.client_rec)) {
                            props.history.push({
                                pathname: '/dashboard',
                                state: { msg: records.msg, campaign_id: records.campaign_id }
                            });
                        }


                        setInfo(records.client_rec);
                        setAgentNote(records.client_rec.agent_note);
                        setContactMood(records.client_rec.contact_mood);
                        setClientId(records.client_rec.client_id);
                        setCoachId(records.client_rec.coach_id);
                        setLoaded(true);
                        setCallStatus(records.call_status);
                        setCampaignInfo(records.campaign);
                        setScriptBody(records.script_body);
                        handleOutCall();
                        getCallHistory(records.client_rec.client_id, records.campaign.campaign_id);
                    } else {
                        setLoaded(true);
                        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    setLoaded(true);
                    console.error("Error:", error);
                });
        }
        if (query.get("id") && dialer_type ==='predictive_dialer') {
            
            fetch(`${ApiUrl}` + url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) { 
                        let records = response.data;
                        if (isObjectEmpty(records.client_rec)) {
                            props.history.push({
                                pathname: '/dashboard',
                                state: { msg: records.msg, campaign_id: records.campaign.campaign_id}
                            });
                        }
                        setClientId(records.clientIds);
                        setCoachId(records.coachId);
                        setLoaded(true);
                        setCallStatus(records.call_status);
                        setCampaignInfo(records.campaign);

                        setScriptBody(records.script_body);
                        handleOutCall();
                        getCallHistory(records.client_rec.client_id, records.campaign.campaign_id);
                    } else {
                        setLoaded(true);
                        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                .catch((error) => {
                    setLoaded(true);
                    console.error("Error:", error);
                });
        }
    };
    const getCallHistory = (client_id, campaign_id) => {
        fetch(`${ApiUrl}` + "get/call/history/" + client_id + '?campaign_id=' + campaign_id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setActivity(response.data.records); 
                    setTotalCalls(response.data.statistics.total_calls); 
                    setCallsDuration(response.data.statistics.calls_duration); 
                    setPausedDuration(response.data.statistics.paused_duration); 
                    setTotalSales(response.data.statistics.total_sales);
                    setTotalCallBack(response.data.statistics.total_call_back);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

   const logoutAgents = (record, index) => {
         fetch(`${ApiUrl}` + "handle/agent/logout", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ campaign_id: campaignInfo.campaign_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    history.push({
                        pathname: '/dashboard',
                        state: { back: 'logout' },
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const AddCallStatus = async () => {
        console.log(dispositionId, 'dispositionId');
              console.log(CallSid, 'dispositionId')
        if (dispositionId != '' && CallSid !='') {
            let values = {};
            values.client_id = info.client_id;
            values.contact_mood = contactMood;
            values.agent_note = agentNote;
            if (dispositionId < 1) {
                swal({
                    text: "Sorry! No selected disposition found",
                    icon: "error",
                });
                return false;
            }
            values.call_status = dispositionId;
            values.campaign_id = campaignInfo.campaign_id;
            values.call_sid = CallSid;
            fetch(`${ApiUrl}` + "add/call/status", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify(values),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === true) {
                        swal({
                            text: "Changes saved successfully.",
                            icon: "success",
                        })
                        .then((confirmation) => {
                            if (confirmation) {
                              window.location.reload();
                            }
                        });
                      
                    } else {
                        toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        }else{
             swal({
                    text: "Sorry! Please initiate call to perform this action",
                    icon: "error",
                });
                return false;
        }
    };

   

  // Array of card data
  const cards = [
    { title: total_calls, subtitle: 'Total Calls' },
    { title: calls_duration, subtitle: 'Call Duration'},
    { title: paused_duration, subtitle: 'Paused Duration'},
    { title: total_sales, subtitle: 'Sales'},
    { title: total_call_back, subtitle: 'Call Back'},
  ];

  // Inline styles as JavaScript objects
  const cardStyle = {
    border: 'none',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
  };

  const cardHoverStyle = {
    transform: 'translateY(-10px)',
  };

  const cardTitleStyle = {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  };

  const cardSubtitleStyle = {
    fontSize: '1rem',
    color: '#6c757d',
    marginBottom: '1rem',
  };




  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
    return (
        <React.Fragment>
            <div className="comapign-dialer" >

                <ToastContainer />
                <div className="top_bar bg-white">
                    <Container fluid>
                        <Row>
                          <Col md={12} style={{ height: '73px' }}>
                            <Row>
                              <Col md={3}>
                                <div
                                  className="card"
                                  style={{
                                    border: 'none',
                                    borderRadius: '10px',
                                    transition: 'transform 0.3s',
                                    transform: 'none',
                                  }}
                                >
                                  <div className="card-body">
                                    <h6
                                      className="card-subtitle"
                                      style={{
                                        fontSize: '1rem',
                                        color: 'rgb(108, 117, 125)',
                                        marginBottom: '1rem',
                                      }}
                                    >
                                    {/*  <i className="fas fa-clock me-2"></i>
                                      10:36:57*/}
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                              <Col md={7}>
                               {
                                    (!isObjectEmpty(info) || clientId) && (
                                        <div className="mt-3">
                                            {callToken ? (
                                                <Phone paramsters={paramsters} getCallSid={getCallSid} dialStatus={'auto'} />
                                            ) : (
                                                <p>Loading...</p>
                                            )}
                                        </div>
                                    )
                                }
                              </Col>
                              <Col md={2} className="d-flex justify-content-end align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="me-3">
                                    {uInfo().name}
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-pill"
                                    onClick={() =>
                                      history.push({
                                        pathname: '/dashboard',
                                        state: { back: 'logout' },
                                      })

                                    }
                                     onClick={() => logoutAgents()}
                                  >
                                    <i className="fas fa-power-off"></i>
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                    </Container>
                </div>
                <div className="row justify-content-center">
                    {cards.map((card, index) => (
                      <div className="col-lg-2 col-md-4 mt-5 mb-5" key={index}>
                        <div
                          className="card text-center"
                          style={cardStyle}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = cardHoverStyle.transform)}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'none')}
                        >
                          <div className="card-body">
                            <h5 className="card-title" style={cardTitleStyle}>{card.title}</h5>
                            <h6 className="card-subtitle" style={cardSubtitleStyle}>{card.subtitle}</h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                <Container fluid>
                   <div className="section-dialer pt-3">

                        <div className="row">
                            <div className="col-md-5">
                                <Card>
                                    <CardBody>
                                        <h3>Lead Information</h3>
                                         <div className="container">
                                          <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                              <a
                                                className={`nav-link ${activeTab === 'contact-info' ? 'active' : ''}`}
                                                id="contact-info-tab"
                                                 style={{color: "#000"}}
                                                href="#contact-info"
                                                role="tab"
                                                aria-controls="contact-info"
                                                aria-selected={activeTab === 'contact-info'}
                                                onClick={() => handleTabClick('contact-info')}
                                              >
                                                <i className="fas fa-address-card me-2"></i>
                                                Contact Info
                                              </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                              <a
                                                className={`nav-link ${activeTab === 'call-history' ? 'active' : ''}`}
                                                id="call-history-tab"
                                                style={{color: "#000"}}
                                                href="#call-history"
                                                role="tab"
                                                aria-controls="call-history"
                                                aria-selected={activeTab === 'call-history'}
                                                onClick={() => handleTabClick('call-history')}
                                              >
                                                <i className="fas fa-history me-2"></i>
                                                Call History
                                              </a>
                                            </li>
                                          </ul>

                                          <div className="tab-content">
                                            <div
                                              className={`tab-pane fade ${activeTab === 'contact-info' ? 'show active' : ''}`}
                                              id="contact-info"
                                              role="tabpanel"
                                              aria-labelledby="contact-info-tab"
                                            >
                                              <div className="client-form">
                                                {!isObjectEmpty(info) && (
                                                  <ClientCreateUpdate
                                                    id={info.client_id}
                                                    coach_id={info.coach_id}
                                                    getData={getAlert}
                                                    from="companyDetails"
                                                  />
                                                )}
                                              </div>
                                            </div>
                                            <div
                                              className={`tab-pane fade ${activeTab === 'call-history' ? 'show active' : ''}`}
                                              id="call-history"
                                              role="tabpanel"
                                              aria-labelledby="call-history-tab"
                                            >
                                              <div>
                                                <h5>Call History</h5>
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th> From </th>
                                                                        <th> To </th>
                                                                        <th> Direction </th>
                                                                        <th> Duration </th>
                                                                        <th> Dated </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        activity.map((row, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td> {row.froms} </td>
                                                                                    <td> {row.tos} </td>
                                                                                    <td> {row.direction} </td>
                                                                                     <td> {row.call_duration} </td>
                                                                                    <td> {row.created_at} </td>
                                                                                   

                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className="col-md-4">
                                <Card>
                                    <CardBody>
                                        <div dangerouslySetInnerHTML={{ __html: scriptBody }} />
                                    </CardBody>
                                </Card>
                            </div>

                            <div className="col-md-3">
                                <Card>
                                    <CardBody>
                                        <h3>Log Activity</h3>
                                        <Card>
                                            <CardBody style={{ textAlign: 'start'}}>
                                                <h3>Call Disposition</h3>
                                                <select className="form-control" value={info.status_id} onChange={(e) =>{
                                                    setDispositionId(e.target.value);
                                                    setDispositionStatus(false);
                                                     setInfo((prevInfo) => ({
                                                        ...prevInfo, 
                                                        status_id: e.target.value, 
                                                    }));
                                                }}>
                                                    <option>Select Disposition Status</option>
                                                    {callStatus && callStatus.map((status, i) => (
                                                        <option key={i} value={status.status_id}>
                                                            {status.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </CardBody>
                                        </Card>

                                        <Card>
                                            <CardBody style={{ textAlign: 'start'}}>
                                                <h3>Agent Note</h3> 
                                               <AvForm className="needs-validation">
                                                    <div className="note">
                                                        <div className="mb-3">
                                                            {/* Textarea with onChange event */}
                                                            <AvField
                                                                className="form-control"
                                                                type="textarea"
                                                                name="activity"
                                                                value={agentNote}
                                                                onChange={(e) => setAgentNote(e.target.value)} // Handle text change
                                                                placeholder="Add a note"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </AvForm>

                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody style={{ textAlign: 'start'}}>
                                                <h3>Contact's Mood</h3>
                                                <AvForm className="needs-validation">
                                                    <div className="note">
                                                        <div className="mb-3">
                                                            {/* Bootstrap Button Group for Mood Selection with Emoji */}
                                                            <div className="btn-group" role="group">
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-outline-success btn-sm ${contactMood === "Excellent" ? "active" : ""}`}
                                                                    onClick={() => setContactMood("Excellent")}
                                                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                                >
                                                                    <span style={{ fontSize: '1.5rem' }}>😄</span>
                                                                    Excellent
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-outline-info btn-sm ${contactMood === "Good" ? "active" : ""}`}
                                                                    onClick={() => setContactMood("Good")}
                                                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                                >
                                                                    <span style={{ fontSize: '1.5rem' }}>😊</span>
                                                                    Good
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-outline-warning btn-sm ${contactMood === "Medium" ? "active" : ""}`}
                                                                    onClick={() => setContactMood("Medium")}
                                                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                                >
                                                                    <span style={{ fontSize: '1.5rem' }}>😐</span>
                                                                    Medium
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-outline-danger btn-sm ${contactMood === "Poor" ? "active" : ""}`}
                                                                    onClick={() => setContactMood("Poor")}
                                                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                                >
                                                                    <span style={{ fontSize: '1.5rem' }}>😕</span>
                                                                    Poor
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-outline-dark btn-sm ${contactMood === "Very Bad" ? "active" : ""}`}
                                                                    onClick={() => setContactMood("Very Bad")}
                                                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                                >
                                                                    <span style={{ fontSize: '1.5rem' }}>😞</span>
                                                                    Very Bad
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AvForm> 
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody style={{ textAlign: 'center'}}>
                                                <button 
                                                    onClick={() =>  AddCallStatus()} 
                                                    style={{ marginTop: '10px', width: '100%', borderRadius: '34px', padding: '10px 20px', backgroundColor: '#67a8e4', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                                                     
                                                >
                                                   Save
                                                </button>
                                            </CardBody>
                                        </Card>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
           
                        {page3Class == "slide-in" && (
                            <div className={`page4 ${page3Class} custom-scroll`}>
                                <div>
                                    <div className="modal-head top-heading">
                                        <h2> Dialpad </h2>
                                        <button
                                            onClick={() => {
                                                setpage3Class("slide-out2");
                                            }}
                                            className="btn btn-link text-white"
                                        >
                                            <i className="fas fa-times"> </i>
                                        </button>
                                    </div>
                                    <Card>
                                        <CardBody>
                                             <Dialler paramsters={paramsters} getCallSid={getCallSid} dialStatus={'default'} number={""} setNumber={""} />
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        )}
                  

                </Container >
              
                <div className="options-default">

                    <button
                        onClick={() => {
                            setpage3Class("slide-in");
                        }}
                        className="btn btn-md m-2 sms-btn"
                    >
                        DIALPAD
                    </button>
                </div>
               
            </div >
        </React.Fragment >
    )
}

export default CampaignDialer