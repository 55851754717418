import React, { useState } from "react";
import KeypadButton from "./KeypadButton";
import useLoudness from "./hooks/useLoudness";
import useMuteWarning from "./hooks/useMuteWarning";
import "./OnCall.css";

const OnCall = ({ handleHangup, connection,handleDialPad }) => {
  const [muted, setMuted] = useState(false);
  const [running, setRunning, loudness] = useLoudness();
  const [showMuteWarning] = useMuteWarning(loudness, running);

  const handleMute = () => {
    connection.mute(!muted);
    setMuted(!muted);
    setRunning(!muted);
  };

  const muteWarning = (
    <p className="warning">Are you speaking? You are on mute!</p>
  );

  return (
    <>
      {showMuteWarning && muteWarning}
      <div className="call">
        
        <div className="hang-up">
  <KeypadButton handleClick={handleHangup} color="red">
    <i className="fas fa-phone"></i>
  </KeypadButton>

  <KeypadButton handleClick={handleMute} color={muted ? "red" : ""}>
    <i className={muted ? "fas fa-microphone-slash" : "fas fa-microphone"}></i>
  </KeypadButton>
  {/*
  <button className="keypad-button blue" onClick={handleDialPad}>
    <i className="fas fa-th"></i>
  </button>
  */}
</div>

         
      </div>
    </>
  );
};

export default OnCall;
