import React, { useState, useEffect } from "react";
import { Device } from "twilio-client";
import Dialler from "./Dialler";
import KeypadButton from "./KeypadButton";
import Incoming from "./Incoming";
import OnCall from "./OnCall";
import "./Phone.css";
import states from "./states";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const Phone = ({ paramsters, getCallSid, dialStatus }) => {
    const [state, setState] = useState(states.CONNECTING);
    const [to_number, setNumber] = useState(paramsters.phoneno);
    const [from_number, setFromNumber] = useState(paramsters.from_number);
    const [client_id, setClientId] = useState(paramsters.client_id);
    const [coach_id, setCoachId] = useState(paramsters.coach_id);
    const [campaign_id] = useState(paramsters.campaign_id ? paramsters.campaign_id : 0);
    const dial_status = dialStatus ? dialStatus : 'default';
    
    const [conn, setConn] = useState(false);
    const [device, setDevice] = useState(null);
    const [displayMessage, setDisplayMessage] = useState('');
    const [in_state, setStateVal] = useState("Connecting");
    const [isDialPadOpen, setDialPadOpen] = useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        const deviceInstance = new Device();
        deviceInstance.setup(paramsters.token, { debug: true });

        deviceInstance.on("ready", () => {
            setDevice(deviceInstance);
            setState(states.READY);
            if (dial_status === 'auto') {
                 handleCall(deviceInstance);
            }
        });

        deviceInstance.on("connect", (connection) => {
            setConn(connection);
            getCallSid(connection.parameters.CallSid);
            setState(states.ON_CALL);
            setStateVal("ON_CALL");
            setDisplayMessage("Connected with phone number " + connection.parameters.From);
            console.log("Connected paramsters - connect", connection.parameters);
        });

        deviceInstance.on("disconnect", () => {
            setState(states.READY);
            setConn(null);
            setDisplayMessage("Incoming call disconnected");
            setStatusOnReject();
        });

        deviceInstance.on("incoming", (connection) => {
            setState(states.INCOMING);
            setConn(connection);
            setStateVal("Incoming");
            connection.on("reject", () => {
                setState(states.READY);
                setConn(null);
                setDisplayMessage("Incoming call rejected");
            });
        });

        deviceInstance.on("cancel", () => {
            setState(states.READY);
            setConn(null);
            setDisplayMessage("Incoming call canceled");
            setStatusOnReject();
            setDevice(null);
        });

        return () => {
            deviceInstance.destroy();
            setDevice(null);
            setState(states.OFFLINE);
        };
    }, [paramsters.token]);

    const handleCall = (device) => {
        if (device) {
            device.connect({
                to_number: to_number,
                from_number: from_number,
                coach_id: coach_id,
                coach_partner_id: paramsters.coach_partner_id,
                client_id: client_id,
                campaign_id: campaign_id,
            });
        } else {
            console.error("No device available to make a call");
        }
    };

    const setStatusOnReject = () => {
        setStateVal("ON_CALL");
    };

    const handleHangup = () => {
        if (device) {
            device.disconnectAll();
            console.log("Call ended");
        } else {
            console.error("No active device to hang up");
        }
    };

    const handleDialPad = () => {
        setModal(!modal); // Toggle the modal on button click
    };

    let render;
    if (conn) {
        if (state === states.READY || state === states.CONNECTING || state === states.INCOMING) {
            render = (
                <Incoming
                    state={state}
                    displayMessage={displayMessage}
                    connection={conn}
                    setStatusOnReject={setStatusOnReject}
                />
            );
        } else if (state === states.ON_CALL) {
            render = <OnCall handleHangup={handleHangup} connection={conn} handleDialPad={handleDialPad} />;
        }
    } else {
        render = (
            <div className="call">
                <KeypadButton handleClick={() => handleCall(device)} color="green">
                    <i className="fas fa-phone"></i>
                </KeypadButton>
            </div>
        );
    }

    return (
        <>
            {render}
            <Modal
                isOpen={modal}
                role="dialog"
                centered={true}
                toggle={handleDialPad}
            >
                <div className="modal-content">
                    <ModalHeader toggle={handleDialPad}>Dial Pad</ModalHeader>
                    <ModalBody>
                        <Dialler number={to_number} setNumber={setNumber} />
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
};

export default Phone;
