import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Badge from "react-bootstrap/Badge";

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { bcrypt, isAllowed, uInfo, uToken } from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import Dropdown from 'react-bootstrap/Dropdown';

export default class Group extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "ID",
        key: "id",
        sortable: false,
      },
      {
        text: "Name",
        key: "title",
        sortable: false,
      },
      {
        text: "Leads ",
        key: "total_leads",
      },
      {
        text: "Undialed",
        key: "undialed",
      },
      {
        text: "Dials",
        key: "dials",
      },
      {
        text: "Date Created",
        key: "created_at",
      },
      {
        text: "Recycle",
        key: "recycle",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
                <a
                   onClick={() => this.props.history.push("/recycle/option/" + bcrypt(record.id))}
                >
                    <i className="fa fa-recycle" aria-hidden="true"></i>
                </a>
            
            </Fragment>
          );
        },
      },
      {
          text: "Active",
          key: "logout_agents",
          sortable: false,
          cell: (record, index) => {
            return (
              <Fragment key={index}>
              <div>
                <input
                  type="checkbox"
                  id={`switch${index}`}
                  checked={record.isChecked}
                  onChange={(event) => this.handleToggle(record.id, index, event)}
                  switch="success"
                />
                <label
                  htmlFor={`switch${index}`}
                  data-on-label=""
                  data-off-label=""
                ></label>
              </div>
            </Fragment>
            );
          },
        },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      records: [],
      roles: [],
      campaigns: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Phone",
      customDate: true,
      user_id: this.props.user_id ? this.props.user_id : "",
    };
  }
  handleToggle = (id, index,event) => {
        const isChecked = event.target.checked;
       
            this.setState((prevState) => {
              const newRecords = [...prevState.records];
              newRecords[index].isChecked = !newRecords[index].isChecked;
              return { records: newRecords };
            });

        let url = `${ApiUrl}` + "update/list/status";

        fetch(`${url}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
           body: JSON.stringify({ id: id, isChecked: isChecked }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    };
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/phone/list", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };
 
  componentDidMount() {
    if (this.state.user_id == "") {
      this.columns = [
        ...this.columns,
        {
          key: "action",
          text: "Options",
          cell: (record, index) => {
            return (
              <Fragment>
                <Dropdown className="left-shown mb-3">
                  <Dropdown.Toggle drop="start" variant="primary">
                    <b className="fas fa-ellipsis-v" aria-hidden="true"></b>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => this.props.history.push("/phone/list/edit/" + bcrypt(record.id))}
                    >
                      <i className="fas fa-pencil-alt"></i> Edit
                    </Dropdown.Item>

                    <Dropdown.Item 
                    eventKey="2"
                    onClick={() => this.props.history.push("/campaign/attachment/" + bcrypt(record.id))}
                    >
                      <i className="fas fa-paperclip"></i> Attach to Campaign
                    </Dropdown.Item>

                    <Dropdown.Item eventKey="3"
                    onClick={() => 
                      swal({
                        text: "Sorry, this functionality will start working after the campaign dialing functionality is completed.",
                        icon: "warning",
                      })
                  }
                    >
                      <Link to="#" className="">
                        <i className="fas fa-download"></i> Download
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => this.deleteRecord(record, index)}
                    >
                      <i className="fas fa-trash-alt"></i> Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Fragment>
            );
          },
        },
      ];
    }

    this.getData();
    this.getMeta();
  }

  getData = (queryString = "", data) => {
    let url = `${ApiUrl}` + "phone/list";
    
    fetch(`${url}` + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
             records: response.data.records.map((record) => ({
                ...record,
                    isChecked: record.status == "Active",
              })),
          });
        } else {
                    
          
        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getMeta = () => {
    let url = `${ApiUrl}` + "campaigns";
    
    fetch(`${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
              campaigns: response.data.records,
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.user_id == "" && (
          <>
            <Helmet>
              <title>{this.state.page_name} List</title>
            </Helmet>

            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name} List</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name} List</Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  {isAllowed(uInfo(), ["can_create"]) && (
                    <Link
                      type="button"
                      color="info"
                      className="btn btn-info"
                      to={"/import/phone/list"}
                    >
                      <i className="mdi mdi-plus"></i>
                      Click Here to Upload<br />
                      CSV format only
                    </Link>
                  )}
                </div>
              </Col>
            </Row>

             <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={this.handleFilterSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="4">
                          <div className="mb-3">
                            <AvField
                              className="select form-control"
                              type="select"
                              id="s"
                              name="campaign_id"
                              label="Select Campaign"
                            >
                              <option value={""}> Select Campaign </option>
                              {this.state.campaigns && this.state.campaigns.map((row) => {
                                return (
                                  <option key={row.campaign_id} value={row.campaign_id}>
                                    {`${row.title} `}
                                  </option>
                                );
                              })}
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <label className=""> {" - "}</label>
                            <div className="button-items">
                              <Button
                                className="btn-md"
                                color="primary"
                                type="submit"
                              >
                                Filter
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {this.state.user_id != "" && (
                  <CardTitle className="h4">Phone Lists 1</CardTitle>
                )}
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
