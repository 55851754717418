import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Table,
    Alert,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";


import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    hasRole,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import swal from 'sweetalert';
import Badge from "react-bootstrap/Badge";
import Dropdown from 'react-bootstrap/Dropdown';

export default class Campaign extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Campaign",
                key: "title",
            },
            {
                text: "Dial Level",
                key: "dial_level",
                sortable: false,
                cell: (record, index) => {
                  return (
                    <Fragment key={index}>
                      <div>
                        <select
                          type="text"
                          className="form-control"
                          value={record.dial_level}
                          onChange={(e) => this.handleSelectChange(e, record, index)}
                        >
                          {record.dial_type == 'auto_dialer' ? (
                            <option value="1">auto</option>
                          ) : record.dial_type == 'predictive_dialer' ? (
                            <>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </>
                          ) : null}
                        </select>
                      </div>
                    </Fragment>
                  );
                },
            },
            {
                text: "Leads",
                key: "leads",
            },
            {
                text: "Redials",
                key: "redials",
            },
            
            {
                text: "Avg Wait",
                key: "avg_wait",
            },
            {
                text: "Abandon",
                key: "abandon",
            },
            {
                text: "Sales",
                key: "sales",
            },
            {
                text: "Agent In",
                key: "agent_in",
            },
            {
                text: "Logout Agents",
                key: "logout_agents",
                sortable: false,
                cell: (record, index) => {
                  return (
                    <Fragment>
                        <a
                          onClick={this.logoutAgents.bind(this, record, index)}
                        >
                       <i className="fas fa-power-off" aria-hidden="true"></i>

                        </a>
                    
                    </Fragment>
                  );
                },
            },
            {
                text: "Total Calls",
                key: "total_calls",
            },
            {
                text: "Active",
                key: "logout_agents",
                sortable: false,
                cell: (record, index) => {
                  return (
                    <Fragment key={index}>
                    <div>
                      <input
                        type="checkbox"
                        id={`switch${index}`}
                        checked={record.isChecked}
                        onChange={(event) => this.handleToggle(record.campaign_id, index, event)}
                        switch="success"
                      />
                      <label
                        htmlFor={`switch${index}`}
                        data-on-label=""
                        data-off-label=""
                      ></label>
                    </div>
                  </Fragment>
                  );
                },
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                    <Fragment>
                         <button
                            type="button"
                            color="info"
                            className="btn btn-primary btn-sm mb-1 mr-5"
                            onClick={() => this.props.history.push("/campaign/edit/" + bcrypt(record.campaign_id))}
                          >
                            <i className="fas fa-pencil-alt"></i> Edit
                          </button>
                         <button
                            className="btn btn-danger btn-sm mb-1"
                            onClick={this.deleteRecord.bind(this, record, index)}
                          >
                            <i className="fas fa-trash-alt"></i> Delete
                          </button> 
                      </Fragment>

                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            campaign_log: {},
            deal_status: {},
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "Campaign",
            customDate: true,
            ClientSubcribeModal: false,
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
            expandedRows: '',
            expandState: {}
        };
    }

    handleSelectChange = (e, record, index) => {
        const selectedValue = e.target.value;
        // Do something with the selected value, record, and index
        console.log(`Selected value: ${selectedValue}, Record: ${JSON.stringify(record)}, Index: ${index}`);

        // Example: Update the record with the selected value
        const updatedRecords = this.state.records.map((item, idx) =>
          idx === index ? { ...item, dial_level: selectedValue } : item
        );

        this.setState({ records: updatedRecords });

        let url = `${ApiUrl}` + "update/campaign/diallevel";

        fetch(`${url}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
           body: JSON.stringify({ id: record.campaign_id, isChecked: selectedValue }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
              //this.getData();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };
    handleToggle = (campaign_id, index,event) => {
        const isChecked = event.target.checked;
        console.log(`Campaign ID: ${campaign_id}, Checked: ${isChecked}`);
            this.setState((prevState) => {
              const newRecords = [...prevState.records];
              newRecords[index].isChecked = !newRecords[index].isChecked;
              return { records: newRecords };
            });

        let url = `${ApiUrl}` + "update/campaign/status";

        fetch(`${url}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
           body: JSON.stringify({ id: campaign_id, isChecked: isChecked }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
              //this.getData();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    };
    logoutAgents = (record, index) => {
        let url = `${ApiUrl}` + "update/agent/logout";

        fetch(`${url}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
           body: JSON.stringify({ campaign_id: record.campaign_id }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
              this.getData();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    };
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/campaign", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.campaign_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                //               toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "campaigns" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {

                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records.map((record) => ({
                        ...record,
                            isChecked: record.status == "Active",
                        })),
                        roles: response.data.roles,
                    });
                   

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getCampaignLog = (campaign_id) => {
        fetch(`${ApiUrl}` + "campaign/report/log", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: campaign_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        campaign_log: response.data.campaign_log,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    resetDialAttempt = (campaign_id) => {
        fetch(`${ApiUrl}` + "campaign/reset/attempt", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: campaign_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/campaign/list',
                        });
                    }, 2000);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "campaigns" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };



    handleEpandRow = (event, campaign_id) => {


        const currentExpandedRows = this.state.expandedRows;

        const isRowExpanded = currentExpandedRows == campaign_id ? true : false
        console.log('newExpandedRows', isRowExpanded)

        let obj = {};
        isRowExpanded ? (obj[campaign_id] = false) : (obj[campaign_id] = true);

        this.setState({
            expandState: obj
        })

        // If the row is expanded, we are here to hide it. Hence remove
        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ? currentExpandedRows !== campaign_id :
            campaign_id

        console.log(newExpandedRows, 'newExpandedRows', isRowExpanded)

        this.setState({ expandedRows: newExpandedRows })
        this.getCampaignLog(campaign_id);

    }

    render() {
        const queryParams = this.props.location.state ? this.props.location.state : '';
        const sessionMSg = queryParams.msg ? queryParams.msg : '';
        const sessionCampaignId = queryParams.campaign_id ? queryParams.campaign_id : 0;

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name} List
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} List</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} List</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={4}>
                        {/* <div className="page-title-box text-align-right">
                            {hasRole(uInfo(), ["Import Leads"]) && (
                                <Link to={"import/lead"} className="btn  btn-success">
                                    Import
                                </Link>
                            )}
                        </div> */}
                    </Col>
                    <Col sm={3}>
                        <div className="page-title-box text-align-right">
                            {isAllowed(uInfo(), ["can_create"]) && (
                                <Link
                                    type="button"
                                    color="info"
                                    className="btn btn-info"
                                    to={"/campaign/add"}
                                >
                                    <i className="mdi mdi-plus"> </i>
                                    Create Campaign
                                </Link>
                            )}
                        </div>
                    </Col>
                </Row>

                {
                    sessionMSg && sessionMSg != '' && (
                        <>
                            <Alert color="info">{dcrypt(sessionMSg)} <button onClick={() => this.resetDialAttempt(sessionCampaignId)} className="btn btn-danger">Click to reset</button></Alert>
                        </>

                    )
                }

                <ToastContainer />


            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </React.Fragment>
        );
    }
}
