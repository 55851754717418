import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import swal from 'sweetalert';

export default class UserOperation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {},
      page_name: "Recycle",
        dispositions: [],
        groups: [],
        campaigns: [],
    };
  }

  componentDidMount() {
    this.getMeta();
    this.state.id && this.getData(this.state.id);
  }

  getData = (id) => {
    fetch(`${ApiUrl}` + "get/edit/recycle", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedDisposition: response.data.record.dispositions,
            status_id: response.data.record.status_ids,
          });
        }else{ 
          this.setState({
            selectedDisposition: [],
            status_id: '',
            record: {
              ...this.state.record,
              system_disposition: '',
              campaign_id: '',
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getMeta = () => {
     fetch(`${ApiUrl}` + "get/recycle/meta", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.records.groups,
            dispositions: response.data.records.dispositions,
            campaigns: response.data.records.campaigns,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmit = async (event, values) => {
    fetch(`${ApiUrl}` + "edit/recycle/opitons", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          swal({
              text: "Updated successfully.",
              icon: "success",
          }).then(() => {
              this.props.history.push("/phone/list");
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
    handleAgent = (options) => {
      let filter = [];
      if (options && options.length > 0) {
        filter = options.map((fil) => fil.status_id);
      }

      this.setState({
        status_id: filter.toString(),
        selectedDisposition: options,
      });
    };
  
 
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="Col-12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name}</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <ModalBody>
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="id"
                          label="Phone List"
                          value={this.state.record.id}
                          onChange={(e) => {
                            this.setState({
                              record: {
                                ...this.state.record,
                                id: e.target.value,
                              },
                            });
                            this.getData(e.target.value);
                          }}
                          required
                        >
                          <option value="">Select Phone List</option>
                          {this.state.groups.map((row, i) => (
                            <option key={i} value={row.id}>
                              {row.title}
                            </option>
                          ))}
                        </AvField>
                      </div>


                      <div className="mb-3">
                        <label className="control-label">Agent Disposition</label>
                        <Select
                          getOptionLabel={(option) => option.title}
                          getOptionValue={(option) => option.status_id}
                          isMulti={true}
                          value={this.state.selectedDisposition}
                          options={this.state.dispositions}
                          classNamePrefix="select2-selection"
                          name="status_id"
                          onChange={this.handleAgent}
                        />
                        <AvField type="hidden" name="status_id" value={this.state.status_id} />
                      </div>

                      <div className="mb-3">
                        <div className="form-group">
                          <AvGroup check>
                            <AvInput
                              type="checkbox"
                              name="system_disposition"
                              checked={this.state.record.system_disposition == '1'}
                              onChange={(e) =>
                                this.setState({
                                  record: {
                                    ...this.state.record,
                                    system_disposition: e.target.checked ? '1' : '0',
                                  },
                                })
                              }
                            />
                            <Label check htmlFor="checkbox">Recycle System Disposition</Label>
                          </AvGroup>
                        </div>
                      </div>
                       <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="campaign_id"
                          label="Campaign"
                          value={this.state.record.campaign_id}
                          onChange={(e) => {
                            this.setState({
                              record: {
                                ...this.state.record,
                                campaign_id: e.target.value,
                              },
                            });
                          }}
                          required
                        >
                          <option value="">Select Campaign</option>
                          {this.state.campaigns.map((row, i) => (
                            <option key={i} value={row.campaign_id}>
                              {row.title}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Save
                      </button>
                    </ModalFooter>
                  </AvForm>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}