import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select";
import countries from "../../../common/data/countries";

export default class LeadsCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match ? (this.props.match.params.id && dcrypt(this.props.match.params.id)) : this.props.id ? (this.props.id) : null,
      coach_id: this.props.match ? (this.props.match.params.coach_id && dcrypt(this.props.match.params.coach_id)) : this.props.coach_id ? (this.props.coach_id) : null,
      record: [],
      page_name: this.props.match
        ? this.props.match.params.id
          ? "Edit Contact"
          : "Create Contact"
        : "",
      groups: [],
      managers: [],
      group_id: 0,
      country_code: '+1',
      CustomField: [],
      customFieldData: {},
      from: this.props.from ? this.props.from : "",
    };
  }

  componentDidMount() {

    this.state.id != null && this.getData();
    this.getGroups();
    this.getCustomField();
  }

  getAlert() {
    this.setState(
      {
        id: this.props.id,
        coach_id: this.props.coach_id,
        from: this.props.from,
      },
      () => {
        this.getData();
      }
    );
    // this.getData();
    // this.getGroups();
    // this.getCustomField();
    // console.log('getAlert from Child', this.props.id);
  }

  getCustomField = (role) => {
    fetch(`${ApiUrl}` + "get/custom/field", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            CustomField: JSON.parse(response.data.record.custom_fields),
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getGroups = (role) => {
    fetch(`${ApiUrl}` + "groups", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.records,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/client", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        coach_id: this.state.coach_id,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        // console.log(response, "data edit/add");
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedGroup: response.data.record.group_ids,
            group_id: response.data.record.group_id,
            country_code: response.data.record.country_code,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    console.log('ddddddddd')
    values.custom_fields = JSON.stringify(this.state.customFieldData);
    if (this.state.from == "companyDetails") {
      values.company_id = this.props.compannyId;
    }

    if (this.state.id) {
      values.id = this.state.record.client_id
        ? this.state.record.client_id
        : -1;
    }

    fetch(`${ApiUrl}` + "edit/client", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        // console.log(data, 'Editing');
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          if (this.state.from == "companyDetails") {
            this.props.getData();
          } else {
            setTimeout(() => this.props.history.push("/contact/list"), 1000);
          }
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleCountry = (selectedCountry) => {
    this.setState({
      country_code: selectedCountry.code,
    });
  };

  handleGroup = (selectedGroup) => {
    //this.setState({ group_id: selectedGroup.group_id });
    console.log(selectedGroup, 'selectedGroup')
    // var filter = [];
    // if (selectedGroup) {
    //   selectedGroup.map((fil) => {
    //     filter.push(fil.group_id);
    //   });
    // }

    this.setState({
      group_id: selectedGroup.group_id,
      selectedGroup: selectedGroup,
    });
  };

  getFormElement = (elementName, elementSchema) => {
    let oldValue =
      this.state.record.custom_fields != undefined &&
        this.state.record.custom_fields != ""
        ? JSON.parse(this.state.record.custom_fields)
        : "{}";

    const element = {
      key: elementSchema.key,
      name: elementSchema.key,
      label: elementSchema.label,
      options: elementSchema.options,
    };

    if (
      !this.state.customFieldData.hasOwnProperty([element.name]) &&
      oldValue != "{}"
    ) {
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          [element.name]: oldValue[element.name],
        },
      }));
    }

    if (elementSchema.type === "select") {
      return (
        <div className="col-md-6 mb-3" key={element.label}>
          <div className="form-group">
            <label>{`${element.label}`}</label>
            <select
              className="select form-control"
              name={element.name}
              // value={el.type || 'textbox'}
              onChange={this.handleChange.bind(this, elementSchema.type)}
            >
              {element.options.map((row, i) => {
                return (
                  <option
                    key={i}
                    selected={
                      this.state.customFieldData[element.name] == row.option
                        ? "selected"
                        : ""
                    }
                    value={`${row.option}`}
                  >
                    {row.option}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      );
    } else if (elementSchema.type === "radio") {
      return (
        <div className="col-md-6 mb-3" key={element.label}>
          <label>{`${element.label}`}</label>
          <fieldset className="form-group">
            <div className="form-control border-0 p-0 h-auto ">
              {element.options.map((row, i) => {
                return (
                  <div key={i} className="form-check form-check-inline">
                    <input
                      checked={
                        this.state.customFieldData[element.name] == row.option
                          ? "checked"
                          : ""
                      }
                      onChange={this.handleChange.bind(
                        this,
                        elementSchema.type
                      )}
                      name={element.name}
                      type="radio"
                      className="form-check-input"
                      value={row.option}
                    />
                    <label className="form-check-label">{`${row.option}`}</label>
                  </div>
                );
              })}
            </div>
          </fieldset>
        </div>
      );
    } else if (elementSchema.type === "checkbox") {
      return (
        <div className="col-md-6 mb-3" key={element.label}>
          <label>{`${element.label}`}</label>

          <fieldset name={`${element.name}`} className="form-group">
            <div className="form-control border-0 p-0 h-auto is-untouched is-pristine av-valid">
              {element.options.map((row, i) => {
                return (
                  <div key={i} className="form-check form-check-inline">
                    <input
                      onChange={this.handleChange.bind(
                        this,
                        elementSchema.type
                      )}
                      name={`${element.name}`}
                      type="checkbox"
                      className=" form-check-input"
                      checked={
                        this.state.customFieldData[element.name] &&
                          this.state.customFieldData[element.name].includes(
                            row.option
                          )
                          ? "checked"
                          : ""
                      }
                      value={`${row.option}`}
                    />
                    <label className="form-check-label">{`${row.option}`}</label>
                  </div>
                );
              })}
            </div>
          </fieldset>
        </div>
      );
    } else if (elementSchema.type === "date") {
      return (
        <div className="col-md-6 mb-3" key={element.label}>
          <div className="form-group">
            <label>{`${element.label}`}</label>
            <input
              name={element.name}
              type="date"
              data-date=""
              data-date-format="DD MMMM YYYY"
              value={this.state.customFieldData[element.name] ? this.state.customFieldData[element.name].toString().split("/").reverse().join("-") : ''}
              className="form-control"
              onChange={this.handleChange.bind(this, elementSchema.type)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-6 mb-3" key={element.label}>
          <div className="form-group">
            <label>{`${element.label}`}</label>
            <input
              name={element.name}
              type="text"
              value={this.state.customFieldData[element.name]}
              className="form-control"
              onChange={this.handleChange.bind(this, elementSchema.type)}
            />
          </div>
        </div>
      );
      // return <TextField {...props} />
    }
  };

  handleChange(type, e) {
    const { name, value } = e.target;

    if (type == "checkbox") {
      if (this.state.customFieldData.hasOwnProperty([name]) && this.state.customFieldData[name]) {
        console.log(this.state.customFieldData[name], 'this.state.customFieldData.hasOwnProperty([name])');
        if (this.state.customFieldData[name].includes(value)) {
          this.setState((prevState) => ({
            customFieldData: {
              ...prevState.customFieldData,
              [name]: this.state.customFieldData[name].filter(
                (color) => color !== value
              ),
            },
          }));
        } else {
          this.setState((prevState) => ({
            customFieldData: {
              ...prevState.customFieldData,
              [name]: [...prevState.customFieldData[name], value],
            },
          }));
        }
      } else {
        this.setState((prevState) => ({
          customFieldData: {
            ...prevState.customFieldData,
            [name]: [value],
          },
        }));
      }

    } else if (type == "date") {
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          [name]: value.toString().split("-").reverse().join("/"),
        },
      }));
    } else {
      // console.log(name, value)
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          [name]: value,
        },
      }));
    }
  }

  CreateCustomFieldUI() {
    return this.state.CustomField.map((el, i) => this.getFormElement(i, el));
  }

  render() {
    return (
      <React.Fragment>
        {this.state.from != "companyDetails" && (
          <>
            <Helmet>
              <title> {this.state.page_name} </title>
            </Helmet>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />
          </>
        )}
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                // model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="row">
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="first_name"
                      value={this.state.record.first_name}
                      label="First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="last_name"
                      value={this.state.record.last_name}
                      label="Last Name"
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="control-label">Phone List</label>
                    <div className="MainTZone Client One">
                      <Select
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.group_id}
                        isMulti={false}
                        value={this.state.selectedGroup}
                        options={this.state.groups}
                        classNamePrefix="select2-selection"
                        name="groups_id"
                        onChange={(e) => {
                          this.handleGroup(e);
                        }}
                      />
                    </div>
                    <AvField
                      required
                      type="hidden"
                      name="group_id"
                      value={this.state.group_id}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="email"
                      value={this.state.record.email}
                      label="Email"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3 d-none">
                    <label className="control-label">Country</label>
                    <div className="MainTZone Client Two">
                      <Select
                        getOptionLabel={(option) =>
                          option.label + " (" + option.code + ")"
                        }
                        getOptionValue={(option) => option.code}
                        value={countries.filter(
                          ({ code }) => code === this.state.country_code
                        )}
                        isMulti={false}
                        options={countries}
                        classNamePrefix="select2-selection"
                        name="country_id"
                        onChange={(e) => {
                          this.handleCountry(e);
                        }}
                      />
                    </div>
                    <AvField
                      type="hidden"
                      name="country_code"
                      value={this.state.country_code}
                    />
                  </div>
                  <div className="col-md-6 mb-3 d-none">
                    <AvField
                      name="mobile"
                      value={this.state.record.mobile}
                      label="Mobile"
                      className="form-control"
                      placeholder="Enter mobile without country code"
                      type="text"
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <AvField
                      name="phone"
                      placeholder="Enter phone with country code"
                      value={this.state.record.phone}
                      label="Phone Number"
                      className="form-control"
                      type="text"
                      
                    />
                  </div>

                  <div className="col-md-6 mb-3 d-none">
                    <AvField
                      name="phone_extension"
                      value={this.state.record.phone_extension}
                      label="Phone Extension"
                      className="form-control"
                    />
                  </div>
                  {/* <div className="col-sm-8 col-md-6 mb-3 ">
                  </div> */}

                  <div className="col-md-6 mb-3">
                    <AvField
                      name="city"
                      value={this.state.record.city}
                      label="City"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="state"
                      value={this.state.record.state}
                      label="State"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="zip"
                      value={this.state.record.zip}
                      label="Zip"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="address"
                      value={this.state.record.address}
                      label="Address"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="company_name"
                      value={this.state.record.company_name}
                      label="Company Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3 d-none">
                    <AvField
                      name="linkedin"
                      value={this.state.record.linkedin}
                      label="LinkedIn"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="website"
                      value={this.state.record.website}
                      label="Website"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="title"
                      value={this.state.record.title}
                      label="Title"
                      className="form-control"
                    />
                  </div>
                  {this.state.CustomField &&
                    this.state.CustomField.length > 0 &&
                    this.CreateCustomFieldUI()}
                </ModalBody>
                <ModalFooter>
                  {this.state.from != "companyDetails" && (
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </Button>
                  )}

                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
        {/* /////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
