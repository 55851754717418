var countries = [
    { id: 'usa', label: 'USA / Canada', code: '+1' },
    { id: 'australia', label: 'Australia', code: '+61' },
    { id: 'uk', label: 'United Kingdom', code: '+44' },
    { id: 'newzealand', label: 'New Zealand', code: '+64' },
    { id: 'netherlands', label: 'Netherlands', code: '+31' },
    { id: 'southafrica', label: 'South Africa', code: '+27' },
    { id: 'ireland', label: 'Ireland', code: '+353' },
    { id: 'mexico', label: 'Mexico', code: '+52' },
    { id: 'vanuatu', label: 'Vanuatu', code: '+678' },
    { id: 'pakistan', label: 'Pakistan', code: '+92' },
    { id: 'uae', label: 'UAE', code: '+971' },
    { id: 'germany', label: 'Germany', code: '+49' },
    { id: 'france', label: 'France', code: '+33' },
    { id: 'italy', label: 'Italy', code: '+39' },
    { id: 'japan', label: 'Japan', code: '+81' },
    { id: 'china', label: 'China', code: '+86' },
    { id: 'brazil', label: 'Brazil', code: '+55' },
    { id: 'india', label: 'India', code: '+91' },
    { id: 'russia', label: 'Russia', code: '+7' },
    { id: 'southkorea', label: 'South Korea', code: '+82' },
    { id: 'argentina', label: 'Argentina', code: '+54' },
    { id: 'sweden', label: 'Sweden', code: '+46' },
    { id: 'norway', label: 'Norway', code: '+47' },
    { id: 'denmark', label: 'Denmark', code: '+45' },
    { id: 'finland', label: 'Finland', code: '+358' },
    { id: 'philippines', label: 'Philippines', code: '+63' },
    { id: 'saudiarabia', label: 'Saudi Arabia', code: '+966' },
    { id: 'egypt', label: 'Egypt', code: '+20' },
    { id: 'indonesia', label: 'Indonesia', code: '+62' },
    { id: 'spain', label: 'Spain', code: '+34' },
    { id: 'portugal', label: 'Portugal', code: '+351' },
    { id: 'switzerland', label: 'Switzerland', code: '+41' },
    { id: 'belgium', label: 'Belgium', code: '+32' },
    { id: 'austria', label: 'Austria', code: '+43' },
    { id: 'poland', label: 'Poland', code: '+48' },
    { id: 'greece', label: 'Greece', code: '+30' },
    { id: 'hungary', label: 'Hungary', code: '+36' },
    { id: 'turkey', label: 'Turkey', code: '+90' },
    { id: 'malaysia', label: 'Malaysia', code: '+60' },
    { id: 'singapore', label: 'Singapore', code: '+65' },
    { id: 'thailand', label: 'Thailand', code: '+66' },
    { id: 'israel', label: 'Israel', code: '+972' },
    { id: 'nigeria', label: 'Nigeria', code: '+234' },
    { id: 'kenya', label: 'Kenya', code: '+254' },
    { id: 'ghana', label: 'Ghana', code: '+233' },
    { id: 'jamaica', label: 'Jamaica', code: '+1-876' },
    { id: 'chile', label: 'Chile', code: '+56' },
    { id: 'colombia', label: 'Colombia', code: '+57' },
    { id: 'venezuela', label: 'Venezuela', code: '+58' },
    { id: 'peru', label: 'Peru', code: '+51' },
    { id: 'ecuador', label: 'Ecuador', code: '+593' },
    { id: 'cuba', label: 'Cuba', code: '+53' },
    { id: 'iceland', label: 'Iceland', code: '+354' },
    { id: 'luxembourg', label: 'Luxembourg', code: '+352' },
    { id: 'monaco', label: 'Monaco', code: '+377' },
    { id: 'malta', label: 'Malta', code: '+356' },
    { id: 'cyprus', label: 'Cyprus', code: '+357' }
];


export default countries;